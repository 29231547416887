:root {
  --principal-color: #008457;
  --text-color: white;
  --secondary-background: #eaeaeaea;
}

@font-face {
  font-family: Lato-Thin;
  src: url("../fonts/Lato-Thin.ttf");
}

@font-face {
  font-family: Lato-Light;
  src: url("../fonts/Lato-Light.ttf");
}

@font-face {
  font-family: Lato-Regular;
  src: url("../fonts/Lato-Regular.ttf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Lato-Thin;
}

.soon {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999999;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a {
  color: black;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

#Asesoramiento {
  min-height: 90vh;
  margin-top: 20vh;
}

ul.thumbs.animated {
  display: none;
}

.carousel-status {
  display: none;
}

button.control-arrow.control-next {
  background-color: black !important;
  opacity: 1 !important;
  height: 500px;
}

button.control-arrow.control-prev {
  background-color: black !important;
  opacity: 1 !important;
  height: 500px;
}

.obraBlackOut {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}

.obraBlackOut:hover {
  background-color: #0000004d;
  color: white;
}
@media (max-width: 600px) {
  #Carrousel img {
    height: 250px;
  }
  #Carrousel .blackOut {
    height: 250px;
  }
}

@media (max-width: 400px) {
  #Carrousel img {
    height: 200px;
  }
  #Carrousel .blackOut {
    height: 200px;
  }
}

span.carousel-control-next-icon,
span.carousel-control-prev-icon {
  background-color: black;
  border-radius: 100%;
  padding: 10px !important;
}

.carousel-indicators {
  display: none;
}

a.carousel-control-prev,
a.carousel-control-next {
  width: auto;
}

.catalogoCard {
  height: 350px;
  width: 280px;
  box-shadow: 0px 1px 9px 0px rgb(0 0 0 / 12%);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -15px;
  z-index: 1;
  margin-left: 20px;
  margin-right: 20px;
}

.catalogoCard img {
  width: 100%;
  height: 121px;
  object-fit: cover;
}

.catalogoCard p {
  font-family: sans-serif;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}

.catalogoCard p:first-child {
  color: black;
  font-size: 14px;
}

.catalogoCard button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

@media (max-width: 1000px) {
  .catalogoCard:first-child {
    display: none;
  }
  .catalogoCard:last-child {
    display: none;
  }
}
