#Nav {
  background-color: var(--principal-color);
  color: var(--text-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  z-index: 10;
}

#Nav ul {
  list-style: none;
}

#Nav img {
  cursor: pointer;
  transition: all 0.3s;
  height: 8vh;
}

#Nav button {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
}

#Nav li {
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

#Nav img:hover {
  color: var(--secondary-background);
}

#Nav li:hover,
#Nav button:hover {
  color: var(--secondary-background);
}

#Nav .location {
  position: absolute;
  padding: 5px;
  background-color: #91a29c;
  top: 10vh;
  left: 0;
  right: 0;
}

#Nav .location p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-right: 10px;
}

.burgerMenu {
  display: none;
  font-size: 30px;
  margin-right: 10px;
}

.burgerList {
  list-style: none;
  cursor: pointer;
  padding-right: 20px;
}

.burgerList button {
  background-color: transparent !important;
  border: none;
  color: black !important;
  padding: 0;
  box-shadow: none !important;
}

.logoBurger {
  width: 175px;
  padding-left: 32px;
}

.locationBurger {
  position: absolute;
  bottom: 10px;
  padding-left: 20px;
}

@media (max-width: 991px) {
  #Nav ul {
    display: none !important;
  }
  #Nav .burgerMenu {
    display: block;
  }
}

@media (max-width: 527px) {
  #Nav .location {
    display: none;
  }
  .carrouselTopMargin {
    margin-bottom: 10vh !important;
  }
}

#Carrousel img {
  height: 300px;
  object-fit: fill;
}

#Carrousel .blackOut {
  background-color: #0000005e;
  position: absolute;
  top: 0vh;
  left: 0;
  right: 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#Carrousel .blackOut h1,
#Carrousel .blackOut h4 {
  font-family: Lato-Regular;
  color: white;
  text-align: center !important;
}

.productSelect {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  cursor: pointer;
  outline: none;
}

.product-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
}

.product-btn:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--principal-color);
  color: white;
  border: none;
  width: 100%;
}

.brandImgPres {
  position: absolute;
  top: 13vh;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 300px;
  object-fit: cover;
}

#BrandProducts .blackOut {
  background-color: #0000005e;
  position: absolute;
  top: 13vh;
  left: 0;
  right: 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#BrandProducts .blackOut h1,
#BrandProducts .blackOut h4 {
  font-family: Lato-Regular;
  color: white;
  text-align: center !important;
}

#Empresa {
  /* margin-top: 20vh; */
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: rgba(240, 240, 240, 1);
}

.empresa-titles {
  font-family: Lato-Regular;
}

#Empresa p {
  font-family: Lato-Light;
}

#Empresa .empresa-telefonos {
  font-size: 1rem;
}

#Contacto {
  padding-top: 50px;
  min-height: 80vh;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.contactForm input,
.contactForm textarea {
  padding: 10px;
  margin: 10px;
  background-color: none;
  border: none;
  border-bottom: 1px solid black;
  outline: none !important;
}

.contactForm button {
  background-color: var(--principal-color);
  color: white;
  padding: 10px;
  margin: 10px;
  border: none;
}

.certiImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

#Certificaciones h3,
#Certificaciones a {
  color: var(--principal-color);
  font-family: Lato-Regular;
}

@media (max-width: 767px) {
  .infoCertificacion {
    margin-top: 10px;
    padding: 0 !important;
    text-align: center;
  }
  .imgCertificacion {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.productDetailsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.categoryImage {
  width: 35px;
  height: 35px;
}
