.footer {
  background-color: var(--principal-color);
  color: var(--text-color);
}

@media (max-width: 767px) {
  .footerCols {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#Marcas {
  background-color: var(--secondary-background);
  min-height: 70vh;
  display: flex;
  align-items: center;
}

#Marcas .tituloMarcas {
  text-align: center;
  font-size: 1.5rem;
}

#Marcas .infoMarcas {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Marcas .imgMarca {
  width: 80%;
  transition: all 0.2s;
  cursor: pointer;
}

.imgMarca:hover {
  transform: translateY(-15px);
}

#CarrouselFeatured .featuredProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#CarrouselFeatured .imgFeaturedProducts {
  width: 50%;
  height: 200px;
  object-fit: contain;
}

#CarrouselFeatured .featuredProducts .p {
  font-family: "Lato-Thin";
}

#ProductView {
  display: flex;
  align-items: center;
  min-height: 80vh;
}

#ProductView .imgProductView {
  width: auto;
}

#BrandProducts img {
  width: 80%;
}

#BrandProducts .sidebarCategory {
  border: 1px solid var(--secondary-background);
  padding: 10px;
}

#BrandProducts .sidebarCategory ul {
  list-style: none;
  padding-left: 0;
}

#BrandProducts .imgCategorias {
  transition: all 0.2s;
  cursor: pointer;
  height: 250px;
  object-fit: contain;
}

#BrandProducts p {
  font-family: Lato-Regular;
}

.imgCategorias:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
}

#Marcas .cadaMarca {
  display: flex;
  justify-content: center;
}

#Marcas .imgMarcas {
  width: 70%;
}

#Marcas .fotoMarcas {
  display: flex;
  justify-content: center;
}

#Marcas .infoMarca {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .infoMarca {
    margin-top: 20px;
    width: 70% !important;
  }
}

#ObrasLanding .tituloObras {
  display: flex;
  align-items: center;
  justify-content: center;
}

#ObrasLanding .obraUno {
  align-items: center;
  justify-content: center;
}

#ObrasLanding .obraDos {
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .infoObraDos {
    margin-top: 20px;
  }
}

#Servicios .servicioColumn {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cadaCertificacion {
  background-color: aliceblue;
}
